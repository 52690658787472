'use client';

import React, { useEffect } from 'react';

import ErrorPage from '@/components/ErrorPage';

const Error = ({ error }: { error: Error }) => {
    useEffect(() => {
        // Log the error to an error reporting service
        if (process.env.NODE_ENV !== 'production') {
            console.log(error);
        }
    }, [error]);

    // We dont use translations here, since its highest level of App,
    // and should cover issues with contexts and etc
    return (
        <html>
            <body>
                <ErrorPage />
            </body>
        </html>
    );
};

export default Error;
