import React, { ReactElement } from 'react';

import classes from './error.module.scss';

const ErrorPage = (): ReactElement => (
    <div className={classes.maintenancePage}>
        <div className={classes.headerBanner}>
            <p className={classes.logo}></p>
        </div>
        <h1 className={classes.heading}>Under Maintenance</h1>
        <div className={classes.description}>
            <p>The shop is currently down for maintenance, but will be up again shortly.</p>
            <p>
                We want to make our web-shop shine, and sometimes, this entails closing it for a short while (usually no
                more than 30 mins).
            </p>
            <p>Apologies for the inconvenience, and hope to see you back here in a short while!</p>
        </div>
    </div>
);

export default ErrorPage;
